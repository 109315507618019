import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Blog from './components/Blog/Blog'


export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Blog />} />
      </Routes>
    </div>
  );
}
